/*******************************
        User Overrides
*******************************/
.ui.scrolling.dropdown .menu {
    overflow-x: hidden !important;
    overflow-y: auto !important;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: touch;
    min-width: 100%!important;
    width: auto!important;
}
@media only screen and (max-width: 767px){
  .ui.selection.dropdown .menu {
      max-height: 14.871429rem;
  }
}

@media only screen and (min-width: 768px){
  .ui.selection.dropdown .menu {
      max-height: 14.871429rem;
  }
}
